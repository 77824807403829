<template>
  <div class="pay" v-html="this.form"></div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      form: "",
    };
  },
  created() {},
  mounted() {
    this.zfbPay();
  },
  methods: {
    zfbPay() {
      this.$api.order
        .zfbPayAddress({
          subject: this.$route.query.orderNo,
          out_trade_no: this.$route.query.orderNo,
          total_amount: this.$route.query.actualPrice,
          payType: 5,
          returnUrl: `${window.location.href.split("#")[0]}#/payResult`,
        })
        .then((res) => {
          console.log("zfbPay", res);
          // console.log(res.data.split('&')[1]);

          let arr = res.data
          // console.log(arr.indexOf('&'));

          let num = arr.indexOf('&')
          // console.log(arr.slice(num+1));

          let arrs = arr.slice(num+1)
          // let min = arrs.lastIndexOf('+')
          // let max = arrs.lastIndexOf('&')

          // for(let i =0 ; i<arrs.length; i++){

          // }
          
          // arrs = arrs.join('')
          // console.log(newarr);
          console.log(arr);
          this.$toast(arrs)

           const paramsObj = { orderStr : arr };
                window.webkit.messageHandlers.aliPay.postMessage(paramsObj);




          this.form = res.data;
          // return
          this.$nextTick(() => {
            document.forms[0].submit();
          });
        });
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="less" scoped>
.pay {
  width: 100vw;
  height: 100vh;
}
</style>
